import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import React from 'react';

import { Icon } from '@/components/Icon';

const CustomCheckbox = React.forwardRef<
   HTMLInputElement,
   Omit<CheckboxProps, 'type'>
>((props, ref) => {
   return (
      <Checkbox
         ref={ref}
         value="true"
         icon={
            <Icon
               name="checkmark"
               opacity={props.isChecked || props.checked ? 1 : 0}
               color={props.variant === 'dark' ? 'white' : 'text.primary'}
               boxSize={4}
            />
         }
         {...props}
      />
   );
});

CustomCheckbox.displayName = '';

export default CustomCheckbox;
