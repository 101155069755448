import React from 'react';
import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

type Props = UseRadioProps & {
   children: React.ReactNode;
};

const DashedRadioButton = ({ children, ...props }: Props) => {
   const { getInputProps, getCheckboxProps } = useRadio(props);

   const input = getInputProps();
   const checkbox = getCheckboxProps();

   return (
      <Box as="label" w="full">
         <input {...input} />
         <Box
            {...checkbox}
            cursor="pointer"
            pos="relative"
            borderRadius={'12px'}
            fontSize="xs"
            fontWeight={600}
            bg={'transparent'}
            border="1px dashed"
            borderColor={'accent.darkGreen'}
            overflow="hidden"
            transition="all .3s ease-in-out"
            color="card.secondary"
            _checked={{
               bg: 'card.secondary',
               color: 'white',
               borderColor: 'card.secondary',
               borderStyle: 'solid',
            }}
            _hover={{
               bg: 'card.secondary',
               color: 'white',
               borderColor: 'card.secondary',
            }}
            _focus={{
               boxShadow: 'none',
            }}
            px={6}
            py={3}
         >
            {children}
         </Box>
      </Box>
   );
};

export default DashedRadioButton;
