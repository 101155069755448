import React from 'react';
import { Box, BoxProps, useRadio, UseRadioProps } from '@chakra-ui/react';

type Props = UseRadioProps & {
   children: React.ReactNode;
   boxProps?: BoxProps;
   variant?: 'fillGreen' | 'fillPrimary' | 'ghost';
};

const variantFillGreen: BoxProps = {
   borderRadius: 'md',
   textAlign: 'center',
   bg: 'buttonSecondary.500',
   borderColor: 'transparent',

   _hover: {
      bg: 'accent.green',
      color: 'text.primary',
      borderColor: 'accent.green',
   },

   _checked: {
      bg: 'accent.green',
      color: 'text.primary',
      outlineColor: 'transparent',
      borderColor: 'accent.green',
   },
};

const variantFillPrimary: BoxProps = {
   textAlign: 'left',
   bg: 'background.primary',
   borderRadius: 14,
   pl: 6,
   color: 'white',

   _hover: {
      color: 'background.tertiary',
      bg: 'white',
      borderColor: 'white',
   },

   _checked: {
      color: 'background.tertiary',
      bg: 'white',
      outlineColor: 'transparent',
   },
};

const variantGhost: BoxProps = {
   borderRadius: '12px',
   textAlign: 'center',
   bg: 'transparent',
   borderColor: 'accent.darkGreen',

   _hover: {
      bg: 'accent.darkGreen',
      color: 'white',
      borderColor: 'accent.darkGreen',
   },

   _checked: {
      bg: 'accent.darkGreen',
      color: 'white',
      borderColor: 'accent.darkGreen',
      outlineColor: 'transparent',
   },
};

const VariantMap = {
   fillGreen: variantFillGreen,
   fillPrimary: variantFillPrimary,
   ghost: variantGhost,
};

const CustomRadioButton = ({
   children,
   variant = 'ghost',
   boxProps,
   ...props
}: Props) => {
   const { getInputProps, getCheckboxProps } = useRadio(props);

   const input = getInputProps();
   const checkbox = getCheckboxProps();

   return (
      <Box as="label" w="full">
         <input {...input} />
         <Box
            {...checkbox}
            cursor="pointer"
            pos="relative"
            textAlign="center"
            fontSize="xs"
            fontWeight={600}
            border="1px solid"
            overflow="hidden"
            outline="1px solid"
            outlineColor="transparent"
            transition="all .3s ease-in-out"
            _focus={{
               boxShadow: 'none',
            }}
            py="10px"
            px="14px"
            {...VariantMap[variant]}
            {...boxProps}
         >
            {children}
         </Box>
      </Box>
   );
};

export default CustomRadioButton;
