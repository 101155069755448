import { Button, ButtonProps, ColorProps, IconProps } from '@chakra-ui/react';

interface IProps extends ButtonProps {
   icon?: React.ReactElement;
   iconColor?: ColorProps['stroke'];
}

const CustomIconButton: React.FC<IProps> = ({
   icon: Icon,
   iconColor,
   ...rest
}) => {
   const iconProps: IconProps =
      !rest.w || rest.w === 'full' || rest.w === '100%'
         ? {
              position: {
                 base: 'absolute',
                 md: 'relative',
              },
              height: 3,
              right: { base: 6, md: 0 },
              marginLeft: { md: '5px' },
              top: { base: '50%', md: '-1px' },
              transform: { base: 'translateY(-50%)', md: 'translateY(0)' },
           }
         : {};

   if (iconColor) {
      iconProps.stroke = iconColor;
   }

   return (
      <Button
         colorScheme="buttonPrimary"
         rightIcon={Icon}
         justifyContent="center"
         type="submit"
         textAlign="center"
         size="md"
         {...rest}
      >
         {rest.children}
      </Button>
   );
};

export default CustomIconButton;
