import {
   Box,
   Flex,
   FormControl,
   FormControlProps,
   FormLabel,
   Text,
} from '@chakra-ui/react';
import { StepDoneIcon } from '@innerwell/chakra/icons';

import { Icon } from '@/components/Icon';
import { InfoTooltip } from '@/components/InfoTooltip';

interface IProps extends FormControlProps {
   name: string;
   helperText?: string | JSX.Element;
   errorMsg?: string;
   tooltipText?: string;
   mode?: 'dark' | 'light';
   showValidIcon?: boolean;
}

const CustomFormControl: React.FC<IProps> = ({
   name,
   helperText,
   errorMsg,
   mode = 'light',
   tooltipText,
   label,
   isRequired,
   isInvalid,
   showValidIcon = false,
   children,
   ...rest
}) => {
   return (
      <FormControl py={2} {...rest}>
         {label ? (
            <Flex gap={1.5} alignItems="center" mb={1}>
               <FormLabel
                  color={mode === 'light' ? 'white' : 'text.primary'}
                  htmlFor={name}
                  mb={0}
                  mr={0}
               >
                  {label}
               </FormLabel>
               {tooltipText ? <InfoTooltip label={tooltipText} /> : null}
            </Flex>
         ) : null}
         <Box pos="relative">
            <>{children}</>
            {isInvalid && (
               <Icon
                  name="alert-fill"
                  zIndex={2}
                  w={6}
                  h={6}
                  color="alert.primary"
                  pos="absolute"
                  right={3}
                  top="13px"
               />
            )}
            {showValidIcon && (
               <StepDoneIcon
                  zIndex={2}
                  w="24px"
                  h="24px"
                  pos="absolute"
                  right={3}
                  top="13px"
               />
            )}
         </Box>

         {errorMsg && isInvalid ? (
            <Text
               mt={1}
               fontSize="xxs"
               textColor="accent.orange"
               fontWeight={600}
            >
               {errorMsg}
            </Text>
         ) : null}
         {isRequired && !errorMsg ? (
            <Text fontSize="xxs" mt={1}>
               *Required
            </Text>
         ) : null}
         {helperText && !errorMsg ? (
            <Text
               fontSize="xxs"
               color={mode === 'light' ? 'white' : 'card.secondary'}
               opacity={0.85}
               mt={1}
               fontWeight={500}
            >
               {helperText}
            </Text>
         ) : null}
      </FormControl>
   );
};

export default CustomFormControl;
